import React from "react";
import { Link } from "react-router-dom";
import noImage from "../../assets/img/product/noimage.jpeg";

function ProductThumb(props) {
  const stripImageAlt = (imgName) => {
    try {
      if (imgName && imgName !== "") {
        return imgName
          .replace("catalog/", "")
          .replaceAll("-", " ")
          .split(".")[0];
      }
      return "";
    } catch (err) {
      return "";
    }
  };

  return (
    <>
      {props.data.length > 0 &&
        props.data.map((product, index) => {
          // console.error('PROD ', product)
          return (
            <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6" key={index}>
              <div className="product-box1">
                <div className="product-img-holder">
                  <div className="hot-sale">
                    {props.featured && <span>HOT</span>}
                    {!props.featured &&
                      product.sale_price &&
                      product.sale_price != "" &&
                      product.sale_price == 0 && <span>FREE</span>}
                    {!props.featured &&
                      product.sale_price &&
                      product.sale_price != "" &&
                      product.sale_price > 0 && <span>SALE</span>}
                  </div>
                  <a href="#">
                    <img
                      alt={stripImageAlt(product.image)}
                      src={
                        product.image && product.image !== ""
                          ? "https://www.ezowokit.com/adminpanel/image/" +
                            product.image
                          : noImage
                      }
                    />
                  </a>
                </div>

                <div className="product-content-holder">
                  <h3>
                    <Link
                      target={props.openInSeparateWindow ? "_blank" : "_self"}
                      to={
                        "/" +
                        (product.seo_url != ""
                          ? product.seo_url
                          : product.name.replace(" ", "-")) +
                        "?p=" +
                        product.product_id
                      }
                    >
                      {product.name.length > 22
                        ? product.name.substr(0, 18) + " ..."
                        : product.name}
                    </Link>
                  </h3>
                  {product.sale_price && product.sale_price !== "" && (
                    <span>
                      <span>${product.price}</span> {product.sale_price}
                    </span>
                  )}
                  {!product.sale_price ||
                    (product.sale_price === "" && (
                      <span>${product.price}</span>
                    ))}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}
export default ProductThumb;
