import React, { useEffect, useState } from "react";
// import { Helmet } from "react-helmet";
import { getPageBySlug } from "../../services/Apis";
// import { logout } from '../../services/Apis'
// import {NavLink, Link, useHistory} from "react-router-dom"

// import Header from '../../layout/header/index'
import FeaturedProducts from "../../components/featured-products/index";
// import SlidingBanner from '../../components/sliding-banner/index'
import SaleProducts from "../../components/sale-products/index";
import AdvantageArea from "../../components/advantage-area/index";
import ContentArea from "../../components/content-area/index";
import MetaData from "../../components/MetaData";
// import Footer from '../../layout/footer/index'
// import AllPagesLinks from '../../components/all-pages-links/index'
// import banner4 from "../../assets/img/banners/banner4.jpeg";

function Home(props) {
  const [pageData, setPageData] = useState([]);
  const IsMobileDevice = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };
  useEffect(() => {
    getPageBySlug("home").then((result) => {
      if (
        result &&
        (result.code === 200 || result.code === 400) &&
        result.data &&
        result.data[0]
      ) {
        setPageData(result.data[0]);
      }
    });
  }, []);

  return (
    <>
      {/* <SlidingBanner /> */}
      {pageData && <MetaData data={pageData} />}

      {IsMobileDevice() && (
        <div className="about-us-page-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="h2 mb-40" style={{ textAlign: "center" }}>
                  {pageData.title}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <FeaturedProducts />
      <SaleProducts />

      {pageData &&
        (pageData.offer_heading !== null ||
          pageData.offer_sub_heading !== null ||
          pageData.offer_sub_heading2 !== null ||
          pageData.banner_url !== null) && (
          <div className="offer-area1 hidden-after-desk">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="brand-area-box-l">
                    {pageData.offer_heading && (
                      <span>{pageData.offer_heading}</span>
                    )}
                    {pageData.offer_sub_heading && (
                      <h1>{pageData.offer_sub_heading}</h1>
                    )}
                    {pageData.offer_sub_heading2 && (
                      <p>{pageData.offer_sub_heading2}</p>
                    )}
                    {pageData.offer_button_text !== null &&
                      pageData.offer_button_url !== null && (
                        <a
                          href={pageData.offer_button_url}
                          target="_blank"
                          className="btn-shop-now-fill"
                        >
                          {pageData.offer_button_text}
                        </a>
                      )}
                  </div>
                </div>
                <div className="countdown"></div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="brand-area-box-r">
                    {pageData.banner_url && pageData.banner_url !== null && (
                      <a href="#">
                        <img
                          style={{ width: "460px", height: "433px" }}
                          src={
                            "https://www.ezowokit.com/adminpanel/image/catalog/banners/" +
                            pageData.banner_url
                          }
                          alt={pageData.offer_heading}
                        />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      {pageData && pageData.description && pageData.description !== "" && (
        <ContentArea description={pageData.description} />
      )}
      <AdvantageArea />

      {pageData && (
        <p
          dangerouslySetInnerHTML={{
            __html: pageData.meta_keyword,
          }}
        />
      )}
    </>
  );
}
export default Home;
